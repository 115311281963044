// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NrCrigAbXA8bqb2d3mo2 {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sAfFR_i1kJtBJanU8lc3 {
  overflow: auto;
  border-top: 1px solid rgb(218, 209, 244);
  height: 100%;
}

.Oy0QmATMjscYhil9_p18 {
  height: 100%;
}

.wTBrYjf618ArMhYin4vY {
  display: flex;
  position: fixed;
  right: 70px;
  bottom: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainView/MainView.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,wCAAwC;EACxC,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,eAAe;EACf,WAAW;EACX,aAAa;AACf","sourcesContent":[".containerSearchWithFilter {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n\n.container {\n  overflow: auto;\n  border-top: 1px solid rgb(218, 209, 244);\n  height: 100%;\n}\n\n.containerTable {\n  height: 100%;\n}\n\n.containerHeaderFloating {\n  display: flex;\n  position: fixed;\n  right: 70px;\n  bottom: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerSearchWithFilter": `NrCrigAbXA8bqb2d3mo2`,
	"container": `sAfFR_i1kJtBJanU8lc3`,
	"containerTable": `Oy0QmATMjscYhil9_p18`,
	"containerHeaderFloating": `wTBrYjf618ArMhYin4vY`
};
export default ___CSS_LOADER_EXPORT___;
