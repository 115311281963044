import { baseUrl, getData } from "@Services/constServices";

export const getModules_service = async () => {
  const url = `${baseUrl}/GetViewModules`;
  const errorMessage =
    "Hubo un problema al traer la información del formulario.";
  const successMessage =
    "La información del formulario se trajo correctamente.";
  return getData(url, successMessage, errorMessage);
};
