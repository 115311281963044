import React from "react";
import MainRoutes from "./routes/MainRoutes";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
const App: React.FC = () => {
  return (
    <BrowserRouter basename="/roles">
      <MainRoutes />
    </BrowserRouter>
  );
};

export default App;
