import { baseUrl, getData } from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const getRoles = () => {
  const url = `${baseUrl}/GetRoles`;
  const ingresa_vista_roles = 4;
  trackUserAction(ingresa_vista_roles);
  const successMessage = "La información de los roles se trajo correctamente.";
  const errorMessage = "Hubo un problema al traer la información de los roles.";
  return getData(url, successMessage, errorMessage);
};
