import { getRoles } from "@Services/roles/getRoles.service";
import { capitalizeFirstLetter } from "@Utilities/capitalizeFirstLetter";

export const adaptRoles = async (
  setStateResponse,
  setIsLoading,
  setErrorCritical
) => {
  const response = await getRoles();
  if (response.isSuccess) {
    setIsLoading(false);
    let adaptRoles = response.data.map((data) => {
      const isSpecial = !data.especial;
      return {
        idRol: data.rolId,
        rol: capitalizeFirstLetter(data.nameRol),
        isSpecial: isSpecial,
      };
    });
    // Ordenar los roles
    adaptRoles.sort((a, b) => {
      if (a.rol === "ADMINISTRADOR") return -1;
      if (b.rol === "ADMINISTRADOR") return 1;
      if (a.rol === "CAJERO") return -1;
      if (b.rol === "CAJERO") return 1;
      return response.data.indexOf(a) - response.data.indexOf(b);
    });
    // Reverse the roles except for the first two
    const firstTwo = adaptRoles.slice(0, 2);
    const rest = adaptRoles.slice(2).reverse();
    adaptRoles = [...firstTwo, ...rest];
    return {
      data: adaptRoles,
    };
  } else {
    setErrorCritical(true);
    setIsLoading(false);
    setStateResponse({
      message: response.message,
      status: response.status,
    });
    return {
      data: [],
    };
  }
};
