// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 550px) {
  ._yisEjZ8u9y2lxwyoFSg {
    width: 100%;
  }
}

@media screen and (width > 550px) {
  .OMi6e2ZrMAhB0yd03XxP {
    display: flex;
    flex-direction: column;

    & span:first-child {
      width: 16px;
      height: 22px;
      border-bottom: 1px solid #dad1f4;
      border-left: 1px solid #dad1f4;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Arrow/Arrow.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;;IAEtB;MACE,WAAW;MACX,YAAY;MACZ,gCAAgC;MAChC,8BAA8B;IAChC;EACF;AACF","sourcesContent":["@media screen and (max-width: 550px) {\n  .moduleItem {\n    width: 100%;\n  }\n}\n\n@media screen and (width > 550px) {\n  .arrow {\n    display: flex;\n    flex-direction: column;\n\n    & span:first-child {\n      width: 16px;\n      height: 22px;\n      border-bottom: 1px solid #dad1f4;\n      border-left: 1px solid #dad1f4;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moduleItem": `_yisEjZ8u9y2lxwyoFSg`,
	"arrow": `OMi6e2ZrMAhB0yd03XxP`
};
export default ___CSS_LOADER_EXPORT___;
