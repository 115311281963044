import { getViewModule_service } from "@Services/roles/getRolViewModule.service";

interface Module {
  moduleId: number;
}
interface View {
  viewId: number;
  moduleId: Module[];
}
interface StartRoute {
  viewId: string;
  viewName: string;
}
interface GetRolViewModuleResponse {
  isSuccess: boolean;
  message: string;
  status: number;
  data: {
    viewModule: View[];
    startView: StartRoute;
    roleName: string;
  };
}

export const adaptGetRolViewModule_adapter = async (
  id: string
): Promise<GetRolViewModuleResponse> => {
  const response = await getViewModule_service(id);
  if (!response.isSuccess) {
    return {
      ...response,
      data: {
        viewModule: [],
        startView: {
          viewId: "0",
          viewName: "",
        },
        roleName: "",
      },
    };
  }
  const adaptViewModule = response.data.viewModule.map((view) => ({
    viewId: view.viewId,
    moduleId: view.moduleId.map((module) => ({
      moduleId: module,
    })),
  }));

  const adaptStartView = {
    viewId: response.data.startView.viewId,
    viewName: response.data.startView.viewName,
  };

  const adaptRoleName = response.data.roleName;

  return {
    ...response,
    data: {
      viewModule: adaptViewModule,
      startView: adaptStartView,
      roleName: adaptRoleName,
    },
  };
};
