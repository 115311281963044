import { Route, Routes } from "react-router-dom";
import { ROUTE_ROLE } from "../models/routes/routes";
import MainView from "../pages/MainView/MainView";
import { RoleInformation } from "@Pages/RoleInformation/RoleInformation";
// @ts-ignore
import { NotFoundPage } from "@viuti/recursos";
import { NewRol } from "@Pages/NewRol/NewRol";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTE_ROLE.ROUTE_MAIN_VIEW} element={<MainView />} />
      <Route path={ROUTE_ROLE.ROUTE_ROLE_INFO} element={<RoleInformation />} />
      <Route path={ROUTE_ROLE.ROUTE_NEW_ROLE} element={<NewRol />} />
      <Route path="*" element={<NotFoundPage />}></Route>
    </Routes>
  );
};

export default MainRoutes;
