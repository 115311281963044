import React from "react";
import styles from "./Loader.module.css";
import { LoadingPulseIcon } from "@Models/icons/icons";

export const Loader = () => {
  return (
    <div id="viuti-front-mainContent" className={styles.container}>
      <figure className={styles.loadingIcon}>
        <img src={LoadingPulseIcon} alt="Cargando..." />
      </figure>
    </div>
  );
};
