import { baseUrl, deleteData } from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const deleteRol = (id) => {
  const url = `${baseUrl}/DeleteRol?idrol=${id}`;
  const elimina_rol = 7;
  trackUserAction(elimina_rol);
  const successMessage = "El rol se eliminó correctamente.";
  const errorMessage = "Hubo un problema al eliminar el rol.";
  return deleteData(url, successMessage, errorMessage);
};
