import { baseUrl, postData } from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const postNewRol = (data) => {
  const url = `${baseUrl}/CreateRol`;
  const crea_nuevo_rol = 5;
  trackUserAction(crea_nuevo_rol);
  const successMessage = "El rol se creó correctamente.";
  const errorMessage = "Hubo un problema al crear el rol.";
  return postData(url, data, successMessage, errorMessage);
};
