import { updateRol_service } from "@Services/roles/updateRol.service";

interface Data {
  deleteView: number[];
  deleteModule: number[];
  newViewModule: {
    viewId: number;
    moduleId: number[];
  }[];
  rolId: number;
  newRolName: string;
  newStartView: number;
}
export const adaptPutRol_adapter = async (
  setStateResponse: any,
  setIsLoadingSaved: any,
  data: Data,
  prevAction: any
) => {
  const response = await updateRol_service(data);
  if (response.isSuccess) {
    setStateResponse({
      message: "Rol editado de manera exitosa.",
      status: 200,
    });
    setTimeout(() => {
      setIsLoadingSaved(false);
      prevAction();
    }, 1000);
  } else {
    setIsLoadingSaved(false);
    setStateResponse({
      message: response.message || "Hubo un problema al editar el rol.",
      status: 400,
    });
    return {
      data: [],
    };
  }
};
