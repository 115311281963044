import { adaptRoles } from "@Adapters/adaptRoles.adapter";
import React, { useEffect, useState } from "react";

const useRoles = (setStateResponse, setIsLoading, setErrorCritical) => {
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await adaptRoles(
        setStateResponse,
        setIsLoading,
        setErrorCritical
      );
      setRoles(response.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { roles, setRoles };
};

export default useRoles;
