import { baseUrl, putData } from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const updateRol_service = async (data) => {
  const url = `${baseUrl}/UpdateRoleV2`;
  const edita_un_rol = 6;
  trackUserAction(edita_un_rol);
  const successMessage = "El rol se actualizó correctamente.";
  const errorMessage = "Hubo un problema al actualizar el rol.";
  return putData(url, data, successMessage, errorMessage);
};
