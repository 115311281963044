// @ts-ignore
import react, { useEffect, useState } from "react";
import styles from "./MainView.module.css";
import {
  HeaderNavigation,
  SearchWithColoredBorder,
  PaginationWrapper,
  SlideNotification,
  CriticalErrorPage,
} from "../../resources";
import { goToNewRole } from "@Utilities/Navigation";
import { useNavigate } from "react-router";
import { RoleList } from "@Components/RoleList/RoleList";
import useRoles from "@Hooks/roles/useRoles";

const MainView = () => {
  const navigate = useNavigate();
  const [stateResponse, setStateResponse] = useState<any>({
    message: "",
    status: 0,
  });
  const [errorCritical, setErrorCritical] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { roles, setRoles } = useRoles(
    setStateResponse,
    setIsLoading,
    setErrorCritical
  );
  const [rolesList, setRolesList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [currentPagination, setCurrentPagination] = useState<number>(1);

  useEffect(() => {
    const rolesListPaginated = roles.slice(
      (currentPagination - 1) * 10,
      currentPagination * 10
    );
    setRolesList(rolesListPaginated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  const filteredRoles = roles.filter((role) => {
    return role.rol.toLowerCase().includes(searchInput.trim().toLowerCase());
  });

  useEffect(() => {
    const rolesListPaginated = filteredRoles.slice(
      (currentPagination - 1) * 10,
      currentPagination * 10
    );
    setRolesList(rolesListPaginated);
  }, [currentPagination]);

  useEffect(() => {
    const rolesListPaginated = filteredRoles.slice(
      (currentPagination - 1) * 10,
      currentPagination * 10
    );
    setRolesList(rolesListPaginated);
    setCurrentPagination(1);
  }, [searchInput]);

  const headerProps = {
    title: "Roles y permisos",
    previousActionMovement: "",
    buttonProps: {
      textBttn: "Nuevo rol",
      handleClick: () => goToNewRole(navigate),
      isDisabled: false,
      isHidden: errorCritical,
      isPrimary: true,
      isLoading: false,
    },
  };

  if (errorCritical) {
    return (
      <div id="viuti-front-mainContent">
        <HeaderNavigation {...headerProps} />
        <CriticalErrorPage />
        <SlideNotification
          state={stateResponse}
          clearStatus={() => setStateResponse({ message: "", status: 0 })}
        />
      </div>
    );
  }

  return (
    <div id="viuti-front-mainContent">
      <HeaderNavigation {...headerProps} />
      <div className={styles.containerSearchWithFilter}>
        <SearchWithColoredBorder
          width={"100%"}
          label={""}
          value={searchInput}
          placeholder={"Buscar rol"}
          setValue={setSearchInput}
          withoutTheContainerWithTheResults={true}
          clearInput={false}
        />
      </div>
      <div className={styles.container}>
        <div className={styles.containerTable}>
          <RoleList
            items={rolesList}
            emptyMessage=""
            isLoading={isLoading}
            setStateResponse={setStateResponse}
            setRoles={setRoles}
          />
          {filteredRoles.length > 10 && (
            <div>
              <PaginationWrapper
                currentPagination={currentPagination}
                setCurrentPagination={setCurrentPagination}
                optionsPerPage={10}
                options={filteredRoles}
              />
            </div>
          )}
        </div>
      </div>
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </div>
  );
};

export default MainView;
