import { postNewRol } from "@Services/roles/postNewRol.service";

export const adaptPostNewRol = async (
  setStateResponse,
  setIsLoadingSaved,
  data,
  prevAction
) => {
  setIsLoadingSaved(true);
  const response = await postNewRol(data);
  if (response.isSuccess) {
    setStateResponse({
      message: "Rol creado de manera exitosa.",
      status: 200,
    });
    setTimeout(() => {
      setIsLoadingSaved(false);
      prevAction();
    }, 1000);
  } else {
    setIsLoadingSaved(false);
    setStateResponse({
      message: response.message || "Hubo un problema al crear el rol.",
      status: 400,
    });
    return {
      data: [],
    };
  }
};
