// @ts-ignore
import { WarningTriangleIcon } from "@Models/icons/icons";
import style from "./ModalDeleteRol.module.css";
// @ts-ignore
import { ModalAcceptCancel } from "@viuti/recursos";
import { deleteRol } from "@Services/roles/deleteRol.service";
import { useState } from "react";

export const ModalDeleteRol = ({
  closeModal,
  setToast,
  idRol,
  setRoles,
  nameRol,
  screenWidth,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <ModalAcceptCancel
      title="Eliminar rol"
      showButtons={{
        showButtonClose: screenWidth < 500,
        showButtonOne: screenWidth >= 500,
        showButtonTwo: true,
      }}
      buttonOne={{
        textButtonOne: "Cancelar",
        actionButtonOne: () => closeModal(),
      }}
      buttonTwo={{
        textButtonTwo: "Eliminar",
        actionButtonTwo: async () => {
          setIsDisabled(true);
          const response = await deleteRol(idRol);
          if (response.isSuccess) {
            setRoles((prev) => prev.filter((rol) => rol.idRol !== idRol));
            setToast({
              message: "rol eliminado correctamente.",
              status: 200,
            });
            return closeModal();
          }
          setIsDisabled(false);
          setToast({
            message: "Hubo un problema al eliminar el rol.",
            status: 400,
          });
        },
        isDisabled: isDisabled,
        isLoading: isDisabled,
      }}
      actionButtonClose={() => closeModal()}
    >
      <div className={style.body}>
        <div className={style.containerForm}>
          <figure>
            <img src={WarningTriangleIcon} alt="Simbolo de advertencia" />
          </figure>
          <p className={style.h3msg}>
            ¿Estás seguro que deseas eliminar este rol?
            <br />
            Ten en cuenta que tal acción no se puede deshacer.
            <strong className={style.strong}>{nameRol}</strong>
          </p>
        </div>
      </div>
    </ModalAcceptCancel>
  );
};
