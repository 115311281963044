// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ckqkoiz6eKvChTq7yELn {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.v4CFEMRKuH0Km0Z091wp {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.v4CFEMRKuH0Km0Z091wp figure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Bi57zpTu70R5duoXbbBG {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #45348e;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.233px;
  margin-top: 10px;
}
.BWHNm9yMBtHyfLm_ODRg {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 4px;
  background: #f9f8fe;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modals/ModalDeleteRol.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,oCAAoC;EACpC,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".body {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background: #fff;\n}\n\n.containerForm {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.containerForm figure {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.h3msg {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  color: #45348e;\n  text-align: center;\n  font-family: \"Mulish\", sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: 0.233px;\n  margin-top: 10px;\n}\n.strong {\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  padding: 10px;\n  border-radius: 4px;\n  background: #f9f8fe;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `Ckqkoiz6eKvChTq7yELn`,
	"containerForm": `v4CFEMRKuH0Km0Z091wp`,
	"h3msg": `Bi57zpTu70R5duoXbbBG`,
	"strong": `BWHNm9yMBtHyfLm_ODRg`
};
export default ___CSS_LOADER_EXPORT___;
