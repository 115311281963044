import { getModules_service } from "@Services/roles/getModules.service";
// @ts-ignore
import { getUserPermissions } from "@viuti/recursos";
interface StartRoute {
  value: string;
  name: string;
}
interface Module {
  moduleId: number;
  moduleName: string;
  hierarchy: number;
  disabled?: boolean;
}
export interface GetModulesResponse {
  isSuccess: boolean;
  message: string;
  status: number;
  data: {
    views: Module[];
    startRoutes: StartRoute[];
  };
}
export const adaptGetModules_adapter =
  async (): Promise<GetModulesResponse> => {
    const response = await getModules_service();
    if (!response.isSuccess) {
      return {
        ...response,
        data: {
          views: [],
          startRoutes: [],
        },
      };
    }
    const userPermissions = getUserPermissions();
    const userHierarchy = userPermissions.plan.hierarchy;

    const adaptModules = response.data.view.map((view) => ({
      ...view,
      modules: view.modules.map((module) => ({
        ...module,
        disabled: module.hierarchy > userHierarchy,
      })),
    }));

    const adaptStartRoutes = response.data.startRoutes.map((route) => ({
      value: String(route.viewId),
      name: String(route.nameView),
    }));

    return {
      ...response,
      data: {
        views: adaptModules,
        startRoutes: adaptStartRoutes,
      },
    };
  };
